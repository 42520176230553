import React from "react";
import background from "../../img/aboutUsPage/background.png"
import "../../css/ContactPage/contactPage.scss"
import { useGlobalProvider } from "../Providers/GlobalProvider";



const ContactPageData = () =>{

    const {fontSemibold} = useGlobalProvider()

    return(<div>
        <div className="mainBackground" style={{backgroundImage:`url(${background}`}}>
        <div className="contactContainer">
                <div className="contactContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Հետադարձ կապ</h2>
                </div>
            </div>
        </div>
        
    </div>)
}


export default ContactPageData