import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import background from "../../img/roomsWithGarages/background.png"

const RoomsWithGaragesData = () =>{


    const {fontSemibold} = useGlobalProvider()

    return(<div>
        <div className="mainBackground" style={{backgroundImage:`url(${background}`}}>
        <div className="contactContainer">
                <div className="contactContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Ավտոտնակով Համարներ</h2>
                </div>
            </div>
        </div>
        </div>
        
    )
}




export default RoomsWithGaragesData