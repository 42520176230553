import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import SliderGarageRoom from "./secSliderGarRoomData"; 
import "../../../css/GarageRoomsPage/sectionSliderGarageRoom/secSlidGarRoom.scss"

const SectionSliderGarageRoom = () =>{

    const {fontRegular} = useGlobalProvider()


    return(
        <div>
            <div className="backgroundSlider">
                <SliderGarageRoom/>
            </div>
            <div className="btn-block-btn">
            <button style={fontRegular} className="reserved">ԱՄՐԱԳՐԵԼ</button>
            </div>
        </div>
    )
}

export default SectionSliderGarageRoom