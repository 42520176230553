import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { route } from "../../route";




const Routes =()=>{


    return <Switch>
        {route.map(({id,path,component})=>{
            return <Route key={id} path={path} exact component={component}/>
        })

        }

    </Switch>
}


export default Routes;