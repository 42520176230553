import React from "react";
import FsliderRoom from "../../../../img/RoomPage/FsliderRoom.png"
import "../../../../css/RoomPage/sectionSliderRoom/sliderRoom.scss"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination,FreeMode,Navigation } from "swiper";
import { useGlobalProvider } from "../../../Providers/GlobalProvider";

export default function SectionSliderRoom({SliderData}) {

  const {fontRegular,fontSemibold} =useGlobalProvider();

  return (
    <>
      <Swiper
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation,Pagination]}
        className="mySwiper"
        navigation
        
      >
        
        {<div className="roomSlider">
             <SwiperSlide>
                       <div style={{backgroundImage:`url(${FsliderRoom})`}} className="fSlideBackground">
                         
                       </div>
              </SwiperSlide>
                <SwiperSlide>
                <div style={{backgroundImage:`url(${FsliderRoom})`}} className="fSlideBackground">
                  
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div style={{backgroundImage:`url(${FsliderRoom})`}} className="fSlideBackground">
                  
                </div>
                </SwiperSlide>
                </div>
        }
      </Swiper>
    </>
  );
}