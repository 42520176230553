import React from "react";
import "../../../css/homeSliderStyle/sectionOffer/sectionOffer.scss"
import backgroundImg from "../../../img/sectionOffer/BackgroundOffer.png"
import Button from "../../Global/Button/Button";
import "../../../css/GlobalStyle/Button/Button.scss"
import roomImg from "../../../img/sectionOffer/roomImg.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";

const SectionOffer = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();


    return(
        <div className="sectionOffer" style={{backgroundImage:`url(${backgroundImg})`}} >
            <div className="sectionOfferContainer">
                <div className="offerDescriptionContainer">
                    <h5 style={fontRegular}>Հատուկ առաջարկ` 20% զեղչ</h5>
                    <p style={fontRegular}>Ամրագրեք Ձեր հանգիստը 13:00-ից 18:00 և ստացեք  20% զեղչ բոլոր Լյուքս սենյակների համար</p>
                    <Button
                    cn="reserve"
                    title="ԱՄՐԱԳՐԵԼ"
                     />
                 </div>
                 
                <div >
                    <img className="roomImg" src={roomImg}/>
                </div>
            </div>
        </div>
    )
}


export default SectionOffer