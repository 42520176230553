import HomePage from "./Components/HomePage/HomePage";
import { HOME_PAGE,HOTEL,ABOUT_US,CONTACT_PAGE,ROOM ,ROOMS,ROOMSWITHGARAGES,RESTAURANTPAGE, POOLPAGE, OPENAIR, KIDSZONE, FITNESS} from "./urls";
import HotelPage from "./Components/HotelPage/HotelPage";
import AboutPage from "./Components/AboutPage/AboutPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import RoomPage from "./Components/RoomPage/RoomPage";
import PageRooms from "./Components/PageRooms/PageRooms";
import PageRoomsWithGarages from "./Components/RoomsWithGarages/PageRoomsWithGarages";
import RestaurantPage from "./Components/Restaurant/RestaurantPage";
import PoolPage from "./Components/Pool/PoolPage";
import OpenAir from "./Components/openAir/OpenAir";
import KidsZone from "./Components/KidsZone/KidsZone";
import Fitness from "./Components/Fitness/Fitness";

export const route =[
    {
        id:1,
        path:HOME_PAGE,
        component:HomePage,
        name:HomePage,
    },
    {
        id:2,
        path:HOTEL,
        component:HotelPage,
        name:HotelPage
    },
    {
        id:3,
        path:ABOUT_US,
        component:AboutPage,
        name:AboutPage
    },
    {
        id:4,
        path:CONTACT_PAGE,
        component:ContactPage,
        name:ContactPage
    },
    {
        id:5,
        path:ROOM,
        component:RoomPage,
        name:RoomPage
    },
    {
        id:6,
        path:ROOMS,
        component:PageRooms,
        name:PageRooms
    },
    {
        id:7,
        path:ROOMSWITHGARAGES,
        component:PageRoomsWithGarages,
        name:PageRoomsWithGarages
    },
    {
        id:8,
        path:RESTAURANTPAGE,
        component:RestaurantPage,
        name:RestaurantPage
    },
    {
        id:9,
        path:POOLPAGE,
        component:PoolPage,
        name:PoolPage
    },
    {
        id:10,
        path:OPENAIR,
        component:OpenAir,
        name:OpenAir
    },
    {
        id:11,
        path:KIDSZONE,
        component:KidsZone,
        name:KidsZone
    },
    {
        id:12,
        path:FITNESS,
        component:Fitness,
        name:Fitness
    }
]