import Footer from "./assets/Components/footer/Footer";
import Header from "./assets/Components/header/Header";
import Routes from "./assets/Components/Routes/Routes";
import "./assets/css/GlobalStyle/GlobalStyle.scss";

function App() {

  

  return (
    <div className="App">
        <Header/>
        <Routes/>
        <Footer/>
    </div>
  );
}

export default App;
