import React from "react";
import background from "../../img/aboutUsPage/background.png"
import "../../css/AboutPage/aboutPageData.scss"
import { useGlobalProvider } from "../Providers/GlobalProvider";



const AboutPageData = () => {

    const {fontSemibold} =useGlobalProvider();


    return(<div className="aboutMainPage">
            <div className="mainBackground" style={{backgroundImage:`url(${background}`}}>
            <div className="aboutContainer">
                <div className="aboutContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Մեր մասին</h2>
                </div>
            </div>
            </div>
        </div>
    )
}


export default AboutPageData