import React from "react";
import "../../../css/AboutPage/sectionFollowUs/followUs.scss"
import imgBlock from "../../../img/aboutUsPage/sectionFollowUs/followUs.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";


const FollowUs = () =>{

        
        const {fontThin} = useGlobalProvider()


    return(
        <div className="followUs">
            <div className="startLine">
                <div className="lLine"></div>
                <h3 style={fontThin}>Հետևիր @ladefense Insta-Էջին</h3>
                <div className="rLine"></div>
            </div>
            <img src={imgBlock}/>
        </div>
    )
}



export default FollowUs