import React from "react";
import SectionSliderRoom from "./SectionSliderRoom";
import "../../../../css/RoomPage/sectionSliderRoom/sliderRoom.scss"
import { useGlobalProvider } from "../../../Providers/GlobalProvider";


const SectionSlider = () => {

    const {fontRegular} = useGlobalProvider()

    return(<div>
                <div className="backDivSlider">
                    <SectionSliderRoom/>
                </div>
                <div className="blockBtn">
                    <button style={fontRegular} className="res">ԱՄՐԱԳՐԵԼ</button>
                </div>
        </div>
    )
}


export default SectionSlider