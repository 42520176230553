import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import "../../css/Fitness/fitness.scss"
import fitnessBackground from "../../img/fitness/fitnessBackground.png"


const FitnessData = () => {

    const {fontSemibold} =useGlobalProvider();

    return(<div className="FitnessMainPage" style={{backgroundImage:`url(${fitnessBackground}`}}>
    <div className="backMain">
        <div className="FitnessContainer">
        <div className="FitnessContainerData">
            <h1>La Defense</h1>
            <h2 style={fontSemibold}>Ֆիթնես Սրահ</h2>
            </div>
        </div>
        </div>  
    </div>)
}

export default FitnessData