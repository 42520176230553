import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import backgroundMain from "../../img/RestaurantPage/backgroundMain.png"
import "../../css/Restaurant/restaurant.scss"


const RestaurantPageData = () =>{

const {fontRegular,fontSemibold} =useGlobalProvider();

    return(<div className="RestaurantMainPage" style={{backgroundImage:`url(${backgroundMain}`}}>
        <div className="backgroundMain">
            <div className="RestaurantContainer">
            <div className="RestContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Ռեստորան</h2>
                </div>
            </div>
        </div>
        
    </div>)
}




export default RestaurantPageData