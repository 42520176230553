import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/hotelPage/sectionPoolGame/poolGame.scss"
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import poolGameBar from "../../../img/hotelPage/sectionPoolGame/poolGameBar.png"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"



const PoolGame = () => {

    const {fontRegular,fontSemibold} = useGlobalProvider()



    return(
        <div className="poolGame">
                <img className="foPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Բիլիարդ բառ</h2>
                </div>
                <div className="sectionPoolGameContainer">
                <div>
                    <h5 style={fontRegular}>Բիլիարդ բառ</h5>
                    <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    
                </div>
                <img className="sectionPoolGameImg" alt="GymZone" src={poolGameBar} />
            </div>
        </div>
    )
}


export default PoolGame