import React from "react";
import { useGoogleMaps} from "react-hook-google-maps";

const GoogleMap = () => {

    
    const { ref } = useGoogleMaps(
        "AIzaSyDm_2GRWMqkXimHOgriKbw0kUxIyAn5vMk",
        {
            center: { lat: 40.19195, lng: 44.4322 },
            zoom: 15,
        },
    );

    return(<div className="mapGoogle" ref={ref} style={{ width: '33.203125vw', height: '23.307291666666668vw'}} >
        
    </div>)
}


export default GoogleMap