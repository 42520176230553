import React from "react";
import "../../../../css/ContactPage/sectionContactUs/sectionContact/sectionContact.scss"
import location from "../../../../img/contactPage/sectionContact/location.svg"
import instagram from "../../../../img/contactPage/sectionContact/instagram.svg"
import facebook from "../../../../img/contactPage/sectionContact/facebook.svg"
import phone from "../../../../img/contactPage/sectionContact/phone.svg"
import post from "../../../../img/contactPage/sectionContact/post.svg"
import { useGlobalProvider } from "../../../Providers/GlobalProvider";


const Contact = () => {

    const {fontRegular,fontBold} = useGlobalProvider()


    return(<div className="contactBlock">
            <div>
                <div className="address">
                    <h5 style={fontBold} className="AddrTitle">Հասցե</h5>
                    <div>
                        <img alt="locationIcon" className="locationIcon" src={location}/>
                        <span style={fontRegular} className="tichina">Տիչինայի 59/2</span>
                    </div>
                </div>
                <div className="social">
                    <h5 style={fontBold}>Սոց. ցանցեր</h5>
                    <div>
                        <a href="www.instagram.com" target="_blank"><img alt="instagramIcon" className="insta" src={instagram}/></a>
                       <a href="www.facebook.com" target="_blank"> <img alt="facebookIcon" src={facebook} className="face"/></a>
                    </div>
                </div>
            </div>
            <div className="contactWithUs">
                <h5 style={fontBold}>Կապ մեզ հետ</h5>
                <div className="phoneTelBlock">
                    <img alt="PhoneIcon" src={phone}/>
                    <span className="tel"><a style={fontRegular} href="tel:+374 095 231 568">+374 095 231 568</a></span>
                </div>
                <div>
                    <img alt="PostIcon" src={post}/>
                    <span className="mail"><a style={fontRegular} href="mailto:info@ladefense.am">info@ladefense.am</a></span>
                </div>
            </div>
    </div>
    )
}

export default Contact