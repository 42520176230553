import React from "react";
import FixedMenu from "../HomePage/menuSlider";
import AboutPageData from "./AboutPageData";
import FollowUs from "./sectionFollowUs/FollowUs";
import History from "./sectionHistory/History";


const AboutPage = () => {



    return(<div className="aboutPage">
        <FixedMenu/>
        <AboutPageData/>
        <History/>
        <FollowUs/>
        </div>
    )
}

export default AboutPage