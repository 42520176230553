import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import "../../../css/KidsZone/secKidsZone/secKidsZone.scss"
import kidsZoneImg from "../../../img/kidsZone/kidsZoneImg.png"
import kidsZoneGallery from "../../../img/kidsZone/kidsZoneGallery.png"

const SecKidsZone = () => {

    const {fontSemibold,fontRegular} =useGlobalProvider();


    return(<div className="SecKidsZone">
                <img className="KidsZonePattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Մանկական Սրահ</h2>
                </div>
                <div className="sectionKidsZoneMain">
                    <div className="txt-block">
                        <h5 style={fontRegular}>Մանկական Սրահ</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    </div>
                        <img alt="imgKidsZone" className="sectionKidsZoneImg" src={kidsZoneImg}/>
                    
                </div>
                <div className="galleryBlockKidsZone">
                    <img className="galleryKidsZone" src={kidsZoneGallery} alt="galleryKidsZone"/>
                </div>
    </div>)

    
}


export default SecKidsZone