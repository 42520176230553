import React, { useEffect } from "react";
import "../../css/Fitness/fitness.scss"
import FixedMenu from "../HomePage/menuSlider";
import FitnessData from "./FitnessData";
import SecFitness from "./secFitness/SecFitness";


const Fitness = () =>{

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

    return(<div className="Fitness">
        <FixedMenu/>
        <FitnessData/>
        <SecFitness/>
    </div>)
}



export default Fitness