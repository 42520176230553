import React, { useEffect } from "react";
import PoolPageData from "./PoolPageData";
import "../../css/Pool/pool.scss"
import FixedMenu from "../HomePage/menuSlider";
import SecPool from "./sectionPool/secPool";


const PoolPage = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(<div className="poolPage">
        <FixedMenu/>
        <PoolPageData/>
        <SecPool/>
        </div>)
}



export default PoolPage