import React, { useEffect } from "react";
import OpenAirData from "./OpenAirData";
import FixedMenu from "../HomePage/menuSlider";
import SecOpenAir from "./secOpenAir/secOpenAir";


const  OpenAir = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    return(<div className="openAir">
            <FixedMenu/>
            <OpenAirData/>
            <SecOpenAir/>
    </div>)
}



export default OpenAir