import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import poolBackground from "../../img/PoolPage/poolBackground.png"
import "../../css/Pool/pool.scss"

const PoolPageData = () => {

    const {fontSemibold} =useGlobalProvider();

    return(<div className="poolMainPage" style={{backgroundImage:`url(${poolBackground}`}}>
    <div className="backMain">
        <div className="poolContainer">
        <div className="poolContainerData">
            <h1>La Defense</h1>
            <h2 style={fontSemibold}>Լողավազան</h2>
            </div>
        </div>
        </div>  
    </div>)
}


export default PoolPageData