import React,{createContext, useContext, useState,useCallback} from "react";

const GlobalContext =createContext({});


const GlobalProvider = ({children})=>{

    const [language,setLanguage]=useState("am");

    const fontRegular = {fontFamily:language == "am" ? 
                          "Montserrat arm-Regular" : 
                                    language == "ru" ? 
                           "Montserrat Regular"  :
                                    language == "en" ? 
                           "Montserrat Regular"  :
                            null}
    
    const fontBold = {fontFamily:language=="am" ?
                        "Montserrat arm-Bold" :
                                    language == "ru" ?
                        "Montserrat Bold" :
                                    language == "en" ?
                        "Montserrat Bold" :
                            null}
        
    const fontSemibold = {fontFamily:language=="am" ?
                        "Montserrat arm-Semibold" :
                                    language == "ru" ?
                        "Montserrat Semibold" :
                                    language == "en" ?
                        "Montserrat Semibold" :
                            null}

    const MontserratRegular = {fontFamily: "Montserrat Regular"}
    const MontserratLight = {fontFamily: "Montserrat Light"}

    const fontThin = {fontFamily:language=="am" ?
                        "Montserrat arm-Thin" :
                                    language == "ru" ?
                        "Montserrat Thin" :
                                    language == "en" ?
                        "Montserrat Thin" :
                            null}

    return <GlobalContext.Provider value={{
        language,
        setLanguage,
        fontRegular,
        fontBold,
        fontSemibold,
        MontserratRegular,
        fontThin,
        MontserratLight
    }}>
        {children}
    </GlobalContext.Provider>
}

const useGlobalProvider=()=>{
    return useContext(GlobalContext)
}


export {useGlobalProvider,GlobalProvider}







