import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import "../../../css/OpenAir/secOpenAir/secOpenAir.scss"
import openAirImg from "../../../img/openAir/openAirImg.png"
import openAirGallery from "../../../img/openAir/openAirGallery.png"

const SecOpenAir = () => {

    const {fontSemibold,fontRegular} =useGlobalProvider();


    return(<div className="SecOpenAir">
                <img className="OpenAirPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Բացօթյա Սրահ</h2>
                </div>
                <div className="sectionOpenAirMain">
                    <div className="txt-block">
                        <h5 style={fontRegular}>Բացօթյա Սրահ</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    </div>
                        <img alt="imgOpenAir" className="sectionOpenAirImg" src={openAirImg}/>
                    
                </div>
                <div className="galleryBlockOpenAir">
                    <img className="galleryOpenAir" src={openAirGallery} alt="galleryOpenAir"/>
                </div>
    </div>)
} 




export default SecOpenAir