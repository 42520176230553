import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import rightArr from "../../../img/RoomPage/rightArr.png"
import "../../../css/PageRooms/sectionRooms/secRooms.scss"
import RoomBox from "./RoomBox/RoomBox";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"


const SecRooms = () => {


    const {fontSemibold,fontRegular} = useGlobalProvider()


    return(<div className="secRooms">
            <img className="pPattern" src={Pattern}/>
             <div className="start">
                    <div className="StartBlock">
                    <span style={fontRegular} className="startBlock--hotel">Հյուրանոց</span>
                    <img className="rightArr" alt="Arrow" src={rightArr}/>
                    <span style={fontRegular} className="startBlock--Rooms">Համարներ</span>
                    </div>
                <div className="SectionContainer__startS">
                    <div className="SectionContainer__startS--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__startS--title">Համարներ</h2>
                </div>
            </div>
            <div className="descBlock">
                 <p style={fontRegular}>Անզուգական տեսարժան վայրերը շրջապատում են մեր կենտրոնում տեղակայված հյուրանոցը, և 224 համարներից յուրաքանչյուրը մի հայացք է տալիս կենտրոնական Երևանի բազմաթիվ կողմերից մեկին: Մեր 4+ աստղանի կացարանը նրբագեղ կերպով համատեղում է հայկական պատմական և ավանդական նախշերն ու ձևավորումները՝ համաձայն ամենախստապահանջ միջազգային չափանիշների: Ճաշակով արդյունքը յուրահատուկ ժամանակակից հայկական հյուրանոց է, որը դուք միշտ կհիշեք:</p>
            </div>
            <div className="room-box-blck">
                <RoomBox/>
                <RoomBox/>
            </div>
            <div className="room-sBox-blck">
                <RoomBox/>
                <RoomBox/>
            </div>
    </div>
    )
}


export default SecRooms