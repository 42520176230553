import React from "react";
import HotelSlider from "../../HomePage/sectionHotel/HotelSlider";
import onePerson from "../../../img/sectionHotel/bed/onePerson.jpg"
import twoPerson from "../../../img/sectionHotel/bed/twoPerson.jpg"
import deluxe from "../../../img/sectionHotel/bed/deluxe.jpg"
import Button from "../../Global/Button/Button";
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import "../../../css/hotelPage/sectionRooms/sectionRooms.scss"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import { NavLink } from "react-router-dom";
import { ROOMS } from "../../../urls";



const SectionRooms = () => {

    const {fontRegular,fontSemibold} = useGlobalProvider()    

    let sliderDate = [
        {
            id: 1,
            img: onePerson,
            title: 'Մեկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 2,
            img: twoPerson,
            title: 'Երկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 3,
            img: deluxe,
            title: 'Դելուքս',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը:",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 4,
            img: onePerson,
            title: 'Մեկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        }
    ]

    return(
        <div className="sectionRooms">
                <img className="sPattern" src={Pattern}/>
            <div>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Համարներ</h2>
                </div>
                <div className="sliderContainer">
                    <HotelSlider sliderDate={sliderDate}/>
                </div>
                <div className="nextRoomsBLockChange">
                <NavLink to={ROOMS}><div className="nextRoomsBlock">
                    <Button  cn={"all-rooms"} title={"Բոլոր  սենյակները"}/>
                    <img src={arrRight}/>
                </div></NavLink>
                </div>
            </div>
        </div>
    )
}





export default SectionRooms