import React from "react";
import "../../../css/hotelPage/sectionGarageRooms/garageRooms.scss"
import onePerson from "../../../img/hotelPage/sectionGarageRooms/forOnePerson.png"
import twoPerson from "../../../img/hotelPage/sectionGarageRooms/forTwoPerson.png"
import deluxe from "../../../img/hotelPage/sectionGarageRooms/deluxRoom.png"
import HotelSlider from "../../HomePage/sectionHotel/HotelSlider";
import Button from "../../Global/Button/Button";
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { ROOMSWITHGARAGES } from "../../../urls";


const GarageRooms = () => {

    const {fontRegular,fontSemibold} = useGlobalProvider()    

    
    let sliderDate = [
        {
            id: 1,
            img: onePerson,
            title: 'Մեկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 2,
            img: twoPerson,
            title: 'Երկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 3,
            img: deluxe,
            title: 'Դելուքս',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը:",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        },
        {
            id: 4,
            img: onePerson,
            title: 'Մեկտեղանոց',
            info: "Ձեզ ջերմ կդիմավորեն այնտեղ, որտեղ յուրաքանչյուր մասնիկ արտահայտում է ոճի հանդեպ մեր մոտեցումը: ",
            btn1: "ԱՄՐԱԳՐԵԼ",
            btn2: "Մանրամասներ"
    
        }
    ]



    return(
        <div className="garageRooms">
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Ավտոտնակով համարներ</h2>
                </div>
            <div className="sliderContainer">
                <HotelSlider sliderDate={sliderDate}/>
            </div>
            <div className="nextRoomBlockForGarageRooms">
         <NavLink to={ROOMSWITHGARAGES}><div className="nextRoomsBlock">
                    <Button  cn={"all-rooms"} title={"Բոլոր  սենյակները"}/>
                    <img src={arrRight}/>
                </div></NavLink>
            </div>
        </div>
    )
}


export default GarageRooms