import React, { useEffect } from "react";
import FixedMenu from "../HomePage/menuSlider";
import RoomsWithGaragesData from "./RoomsWithGaragesData";
import SecGarageRooms from "./secRoomsGarage/SecGarageRooms";
import SectionSliderGarageRoom from "./sectionSliderGarageRoom/sectionSliderGarageRoom";
import SliderRoomsGarage from "./SlidersRooms/sliderRoomsGarage";



const PageRoomsWithGarages = () =>{

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    return(<div>
            <FixedMenu/>
            <RoomsWithGaragesData/>
            <SecGarageRooms/>
            <SectionSliderGarageRoom/>
            <SliderRoomsGarage/>
    </div>)
}




export default PageRoomsWithGarages