import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import openAirBackground from "../../img/openAir/openAirBackground.png"
import "../../css/OpenAir/openAir.scss"

const OpenAirData = () => {

    const {fontSemibold} =useGlobalProvider();

    return(<div className="openAirMainPage" style={{backgroundImage:`url(${openAirBackground}`}}>
    <div className="backMain">
        <div className="openAirContainer">
        <div className="openAirContainerData">
            <h1>La Defense</h1>
            <h2 style={fontSemibold}>Բացօթյա Սրահ</h2>
            </div>
        </div>
        </div>  
    </div>)
}


export default OpenAirData