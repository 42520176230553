import React from "react";
import { useGlobalProvider } from "../../../Providers/GlobalProvider";
import "../../../../css/RoomPage/sectionGarageRooms/amenities/sectionAmenities.scss"
import polygon from "../../../../img/RoomPage/polygon.svg"


const Amenities = () => {

    const {fontSemibold,fontRegular} = useGlobalProvider()


    return(<div>
        <h3 style={fontRegular} className="amenities">ՀԱՐՄԱՐՈՒԹՅՈՒՆՆԵՐ</h3>
        <div className="amenitiesBlock">
            <div className="fLineBlock">
                <div style={fontRegular} className="fdiv">40-43 ք.մ. մակերես</div>
                <div style={fontRegular} className="fdiv">Մինի-բար</div>
                <div style={fontRegular} className="fdiv">Տեսարան` դեպի քաղաք</div>
                <div style={fontRegular} className="fdiv">Օդափոխման համակարգ</div>
                <div style={fontRegular} className="fdiv">Լոգարան՝ ցնցուղով կամ ջակուզիով</div>
            </div>
            <div className="sLineBlock">
                <div style={fontRegular} className="fdiv">40-43 ք.մ. մակերես</div>
                <div style={fontRegular} className="fdiv">Մինի-բար</div>
                <div style={fontRegular} className="fdiv">Տեսարան` դեպի քաղաք</div>
                <div style={fontRegular} className="fdiv">Օդափոխման համակարգ</div>
                <div style={fontRegular} className="fdiv">Լոգարան՝ ցնցուղով կամ ջակուզիով</div>
            </div>
        </div>
        <img className="Buttondarr" alt="darr" src={polygon}/>
        <button style={fontRegular} className="seeMore">Տեսնել ավելին</button>

    </div>)
}


export default Amenities