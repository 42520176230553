import React from "react";
import background from "../../img/hotelPage/hotelMainPage/mainBackground.png"
import "../../css/hotelPage/mainPageData.scss"
import { useGlobalProvider } from "../Providers/GlobalProvider";


const HotelPageData = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();


    return(
        <div className="hotelMainPage">
            <div className="mainBackground" style={{backgroundImage:`url(${background}`}}>
            <div className="hotelContainer">
                <div className="hotelContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Հյուրանոց</h2>
                </div>
            </div>
            </div>
        </div>
    )
}



export default HotelPageData