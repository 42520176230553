import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/AboutPage/sectionHistory/history.scss"
import history from "../../../img/aboutUsPage/sectionHistory/history.png"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"


const History = () => {

    const {fontRegular,fontSemibold} = useGlobalProvider()


    return(<div className="sectionHistory">
                <img className="fPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Պատմություն</h2>
            </div>
            <div className="historyContainer">
            <div className="blockForDescrip">
                <h5 style={fontRegular}>Հոթել Լա Դեֆենս</h5>
                <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։<br/><br/>
                                        14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։<br/><br/>
                                        14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                                        14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                                        14-րդ հարկում գտնվող Thai Penthouse սպա <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                </div>
                <img alt="About Hotel" src={history}/>
            </div>
    </div>)
}



export default History