import React,{createContext,useEffect, useContext,useRef,useState,useCallback} from "react";
import { useGlobalProvider } from "./GlobalProvider";



const ModalToggleContext = React.createContext({})

const ModalProvider = ({children}) => {

    const {language,setLanguage} = useGlobalProvider()
    
    
    const languageData=[
        {id:1,key:"am",name:"ՀԱՅ"},
        {id:2,key:"en",name:"ENG"},
        {id:3,key:"ru",name:"РУС"},
    ];
    const [activeLanguage,setActiveLanguage]=useState("ՀԱՅ");
    // const [showModalLang,SetShowModalLang]=useState(false);

    // const showLanguageModal =useCallback(() => {
    //                 SetShowModalLang(!showModalLang)
    //                 },[showModalLang])
                           
    const ChangeActiveLanguage =(name,key)=>{
                    setActiveLanguage(name);
                    setLanguage(key)
                    // showLanguageModal()
        } 

        
    const innerModal = () => {
        return (<div >
        {languageData.map(({id,name,key})=>{
            return activeLanguage !==name && 
            <p 
            key={id}
            onClick={()=>ChangeActiveLanguage(name,key)}
            >{name}</p>
        })}</div>)
    }  


    return <ModalToggleContext.Provider value={{
        // showLanguageModal,
        ChangeActiveLanguage,
        activeLanguage,
        setActiveLanguage,
        languageData,
        // showModalLang,
        // SetShowModalLang,
        innerModal,
       
    }}>
        {children}
    </ModalToggleContext.Provider>
}


const useModalProvider = () => {
   return useContext(ModalToggleContext)
}


export {ModalProvider,useModalProvider}