import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import fitnessImg from "../../../img/fitness/fitnessImg.png"
import fitnessGallery from "../../../img/fitness/fitnessGallery.png"
import "../../../css/Fitness/secFitness/secFitness.scss"

const SecFitness = () => {

    const {fontSemibold,fontRegular} =useGlobalProvider();


    return(<div className="SecFitness">
                <img className="FitnessPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Ֆիթնես Սրահ</h2>
                </div>
                <div className="sectionFitnessMain">
                    <div className="txt-block">
                        <h5 style={fontRegular}>Ֆիթնես Սրահ</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    </div>
                        <img alt="imgFitness" className="sectionFitnessImg" src={fitnessImg}/>
                    
                </div>
                <div className="galleryBlockFitness">
                    <img className="galleryFitness" src={fitnessGallery} alt="galleryFitness"/>
                </div>
    </div>)

    
}


export default SecFitness