import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/swipers/LaDefenseLogo.svg"
import "../../css/homeSliderStyle/fixedMenu/fixedMenu.scss"
import { useGlobalProvider } from "../Providers/GlobalProvider";
import { useHistory } from "react-router-dom";
import {HOME_PAGE} from "../../urls"


const FixedMenu = () => {
    let history = useHistory();

    const {fontRegular} =useGlobalProvider();

    return(
        <div className="container__block">
           <NavLink to={HOME_PAGE}><img className="container__block__logo" alt="Logo" src={logo}/></NavLink>
            <ul className="container__block__menu">
                <li>
                    <NavLink style={fontRegular} to="/" className={history.location.pathname === "/" ? "navLink active__link" : "navLink"}>
                             Գլխավոր
                    </NavLink> 
                </li>
                <li>
                    <NavLink style={fontRegular} to="/hotel" className={history.location.pathname === "/hotel" ? "navLink active__link" : "navLink"}>
                            Հյուրանոց
                    </NavLink>                  
                </li>
                <li>
                    <NavLink style={fontRegular} to="/restaurant" className={history.location.pathname === "/restaurant" ? "navLink active__link" : "navLink"}>
                            Ռեստորան
                    </NavLink>  
                </li>
                <li>
                    <NavLink style={fontRegular} to="/pool" className={history.location.pathname === "/pool" ? "navLink active__link" : "navLink"}>
                            Լողավազան
                    </NavLink>
                </li>
                <li>
                    <NavLink style={fontRegular} to="/outdoor" className={history.location.pathname === "/outdoor" ? "navLink active__link" : "navLink"}>
                            Բացօթյա սրահ
                    </NavLink>
                </li>
                <li>
                    <NavLink style={fontRegular} to="/baby-hall" className={history.location.pathname === "/baby-hall" ? "navLink active__link" : "navLink"}>
                            Մանկական սրահ
                    </NavLink>
                </li>
                <li>
                    <NavLink style={fontRegular} to="/gym" className={history.location.pathname === "/gym" ? "navLink active__link" : "navLink"}>
                            Ֆիտնես սրահ
                    </NavLink>
                </li>
                <li>
                    <NavLink style={fontRegular} to="/about-us" className={history.location.pathname === "/about-us" ? "navLink active__link" : "navLink"}>
                            Մեր մասին
                    </NavLink>
                </li>
                <li>
                    <NavLink style={fontRegular} to="/contact" className={history.location.pathname === "/contact" ? "navLink active__link" : "navLink"}>
                            Կապ
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}


export default FixedMenu