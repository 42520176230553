import React from "react";
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import kidsZone from "../../../img/sectionKidsZone/kidsZone.png"
import "../../../css/homeSliderStyle/sectionKidsZone/KidsZone.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { KIDSZONE } from "../../../urls";

const KidsZone = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();
    

    return(
        <div className="sectionKidsZone">
            <img className="fiPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Մանկական սրահ</h2>
            </div>
            <div className="sectionKidsZoneContainer">
                <img className="KidsZoneImg" alt="KidsZone"  src={kidsZone}/>
                <div>
                    <h5 style={fontRegular}>Երեխաների ամենասիրելի վայրը</h5>
                    <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                 <NavLink to={KIDSZONE}><div className="btn-block">
                            <button style={fontRegular}>Իմանալ ավելին</button>
                                <img className="btn-arr-right"  src={arrRight}/>
                    </div></NavLink>
                </div>
            </div>
        </div>
    )
}


export default KidsZone