export const HOME_PAGE="/"
export const HOTEL = "/hotel"
export const LOGIN_PAGE="/login"
export const ABOUT_US = "/about-us"
export const CONTACT_PAGE = "/contact"
export const ROOM = "/room"
export const ROOMS = "/rooms"
export const ROOMSWITHGARAGES = "/rooms-with-garages"
export const RESTAURANTPAGE = "/restaurant"
export const POOLPAGE = "/pool"
export const OPENAIR = "/outdoor"
export const KIDSZONE = "/baby-hall"
export const FITNESS = "/gym"