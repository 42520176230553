import React, { useEffect } from "react";
import FixedMenu from "../HomePage/menuSlider";
import RestaurantPageData from "./RestaurantPageData";
import "../../css/Restaurant/restaurant.scss"
import SecRestaurant from "./sectionRestaurant/secRest";


const RestaurantPage = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])


    return(<div className="restaurantPage">
        <FixedMenu/>
        <RestaurantPageData/>
        <SecRestaurant/>
    </div>)
}



export default RestaurantPage