import React, { useEffect } from "react";
import FixedMenu from "../HomePage/menuSlider";
import PageRoomsData from "./PageRoomsData";
import SecRooms from "./pageRoSecRooms/SecRooms";
import "../../css/PageRooms/pageRooms.scss"

const PageRooms = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div className="pageRooms">
            <FixedMenu/>
            <PageRoomsData/>
            <SecRooms/>
        </div>
    )
}


export default PageRooms