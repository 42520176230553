import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/hotelPage/sectionHammamBath/hammamBath.scss"
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import hammamBath from "../../../img/hotelPage/sectionhammamBath/hammamBath.png"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"



const HammamBath = () => {


    const {fontRegular,fontSemibold} = useGlobalProvider()

    return(
        <div className="hammamBath">
                <img className="thPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Համամ բաղնիք</h2>
                </div>
                <div className="sectionHammamContainer">
                <img className="sectionHammamImg" alt="HammamBath" src={hammamBath}/>
                <div>
                    <h5 style={fontRegular}>Համամ բաղնիք (թուրքական)</h5>
                    <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    
                </div>
            </div>
        </div>
    )
}


export default HammamBath