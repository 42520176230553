import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/RoomPage/sectionGarageRooms/sectionGarageRooms.scss"
import rightArr from "../../../img/RoomPage/rightArr.png"
import roomsGarage from "../../../img/RoomPage/roomsGarage.png"
import Amenities from "./amenities/sectionAmenities";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"


const SectionGarageRooms = () =>{

    const {fontSemibold,fontRegular} = useGlobalProvider()


    return(<div className="SectionGarageRooms">
            
            <div className="start">
                    <div className="StartBlock">
                    <span style={fontRegular} className="startBlock--rooms">Համարներ</span>
                    <img className="rightArr" alt="Arrow" src={rightArr}/>
                    <span style={fontRegular} className="startBlock--garageRooms">Մեկտեղանոց Համարներ</span>
                    </div>
                <div className="SectionContainer__startS">
                    <div className="SectionContainer__startS--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__startS--title">Մեկտեղանոց</h2>
                </div>
            </div>
            <div className="descripBlock">
            <img className="sPattern" src={Pattern}/>
                <p style={fontRegular} className="descripBlock--p">Անզուգական տեսարժան վայրերը շրջապատում են մեր կենտրոնում տեղակայված հյուրանոցը, և 224 համարներից յուրաքանչյուրը մի հայացք է տալիս կենտրոնական Երևանի բազմաթիվ կողմերից մեկին: Մեր 4+ աստղանի կացարանը նրբագեղ կերպով համատեղում է հայկական պատմական և ավանդական նախշերն ու ձևավորումները՝ համաձայն ամենախստապահանջ</p>
                <img alt="roomsGarage" className="roomsGarage" src={roomsGarage}/>
                <Amenities/>
            </div>
            
    </div>)
}

export default SectionGarageRooms