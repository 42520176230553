import React from "react";
import "../../css/FooterStyle/footer.scss"
import logo from "../../img/swipers/LaDefenseLogo.svg"
import facebook from "../../img/footer/facebook.svg"
import instagram from "../../img/footer/instagram.svg"
import phone from "../../img/footer/phone.svg"
import footer from "../../img/footer/footer.png"
import { useGlobalProvider } from "../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import {HOME_PAGE} from "../../urls"




const Footer = () => {

    const {fontRegular} =useGlobalProvider();


    return (
        <div className="footer">
            <img className="footer__pattern" alt="pattern" src={footer}/>
            <div className="footer__container">
                <NavLink to={HOME_PAGE}><img className="footer__logo" alt="Logo" src={logo}/></NavLink>
                <div style={fontRegular} className="footer__contactUs">Կապ մեզ հետ</div>
                <div style={fontRegular} className="footer__reservation">24 ԺԱՄՅԱ ՍՊԱՍԱՐԿՈՒՄ</div>
                <div className="footer__contact-block">
                    <img className="footer__contact-block__phone" alt="phone-icon" src={phone} />
                    <div style={fontRegular} className="footer__contact-block__number"><a href="tel:+374 095 231 568">+374 095 231 568</a></div>
                </div>
                <div className="footer__social-block">
                <a href="www.instagram.com"  target="_blank">
                    <img className="footer__social-block__facebook" alt="facebook-icon" src={facebook} />
                    </a>
                    <a href="www.instagram.com"  target="_blank">
                    <img className="footer__social-block__instagram" alt="instagram-icon" src={instagram} />            
                    </a>
                </div>
                <div className="footer__line"></div>
                <div className="footer__aboutUs-block">
                    <span style={fontRegular} className="footer__aboutUs-block__hotel">© 2022 La Defense | All Rights Reserved</span>
                    <span style={fontRegular} className="footer__aboutUs-block__WeFlex">Developed and designed by WEFLEX</span>
                </div>
            </div>
         

        </div>
         
    )
}


export default Footer