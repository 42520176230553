import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import roomBackground from "../../img/RoomPage/roomBackground.png"



const PageRoomsData = () => {

    const {fontSemibold} = useGlobalProvider()


    return(<div>
            <div className="mainBackground" style={{backgroundImage:`url(${roomBackground}`}}>
                <div className="contactContainer">
                    <div className="contactContainerData">
                    <h1>La Defense</h1>
                    <h2 style={fontSemibold}>Համարներ</h2>
                    </div>
                </div>
            </div>
    </div>)
}



export default PageRoomsData