import React from "react";
import FixedMenu from "../HomePage/menuSlider";
import KidsZoneData from "./KidsZoneData";
import "../../css/KidsZone/kidsZone.scss"
import SecKidsZone from "./secKidsZone/secKidsZone";
import { useEffect } from "react";

const KidsZone = () =>{

    useEffect(()=>{
         window.scrollTo(0, 0);
    },[])

    return(<div className="KidsZone">
        <FixedMenu/>
        <KidsZoneData/>
        <SecKidsZone/>
    </div>)
}



export default KidsZone 