import React, { useRef, useState } from "react";
import rightArr from "../../../img/icons/rightArr.svg"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { ROOM } from "../../../urls";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import 'swiper/css/navigation';
import "../../../css/homeSliderStyle/sectionHotel/sliderSectionHotel.scss"
// import "./styles.css";

// import required modules
import { FreeMode, Pagination, Navigation } from "swiper";
import Button from "../../Global/Button/Button";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";

export default function HotelSlider({sliderDate}) {

  const {fontRegular,fontSemibold} =useGlobalProvider();


  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        modules={[FreeMode, Navigation]}
        className="mySwiper"
        navigation
    >

        {
            sliderDate.map(({id,img,title,info,btn1,btn2}) =>{
                return <SwiperSlide key={id}>
                    <div  className="itemSlider" >
                            <img src={img} className="bed"/>
                            <h2 style={fontRegular}>{title}</h2>
                             <p style={fontRegular}>{info}</p>
                        <div className="btn-block">                            
                            <Button cn="btn-block-fBtn" title={btn1}/>
                         <NavLink to={ROOM}><div className="btn-sBlock">
                                <Button cn="btn-sBlock-btn" title={btn2}/>
                                <img className="btn-arr-right" src={rightArr}/>
                            </div></NavLink>
                        </div>
                    </div>
                </SwiperSlide>
            })
        }
      </Swiper>
    </>
  );
}
