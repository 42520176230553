import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import "../../css/KidsZone/kidsZone.scss"
import kidsZoneBackground from "../../img/kidsZone/kidsZoneBackground.png"


const KidsZoneData = () => {


    const {fontSemibold} =useGlobalProvider();

    return(<div className="kidsZoneMainPage" style={{backgroundImage:`url(${kidsZoneBackground}`}}>
    <div className="backMain">
        <div className="kidsZoneContainer">
        <div className="kidsZoneContainerData">
            <h1>La Defense</h1>
            <h2 style={fontSemibold}>Մանկական Սրահ</h2>
            </div>
        </div>
        </div>  
    </div>)
}



export default KidsZoneData