import React from "react";
import FixedMenu from "../HomePage/menuSlider";
import HotelPageData from "./HotelPageData";
import SectionHotel from "./sectionHotel/SectionHotel";
import "../../css/hotelPage/mainPageData.scss"
import SectionRooms from "./sectionRooms/SectionRooms";
import GarageRooms from "./sectionGarageRooms/sectionGarageRooms";
import HammamBath from "./SectionHammamBath/HammamBath";
import PoolGame from "./sectionPoolGame/PoolGame";
import Sauna from "./sectionSauna/Sauna";



const HotelPage = () => {




    return (<div className="hotelPage">
        <FixedMenu  />
        <HotelPageData/>
        <SectionHotel/>
        <SectionRooms/>
        <GarageRooms/>
        <HammamBath/>
        <PoolGame/>
        <Sauna/>
        </div>
    )
}


export default HotelPage