import React, { useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import rightArr from "../../../img/icons/rightArr.svg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import 'swiper/css/navigation';
// import "./styles.css";

import "../../../css/GarageRoomsPage/slidersRooms/sliderRoomsGarage.scss"
// import required modules
import { FreeMode,Navigation} from "swiper";
import Button from "../../Global/Button/Button";
import { useGlobalProvider } from "../../Providers/GlobalProvider";

export default function SliderGarageRoomsData({sliderDate}) {

  const {fontRegular,fontSemibold} =useGlobalProvider();


  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        modules={[FreeMode,Navigation]}
        className="mySwiper"
        navigation
    >

        {
            sliderDate.map(({id,img,title,info,btn1,btn2}) =>{
                return <SwiperSlide key={id}>
                    <div  className="itemSliderGarRoom" >
                            <img src={img} className="bed"/>
                            <h2 style={fontRegular}>{title}</h2>
                             <p style={fontRegular}>{info}</p>
                        <div className="btn-block">                            
                            <Button cn="btn-block-fBtn" title={btn1}/>
                            <div className="btn-sBlock">
                                <Button cn="btn-sBlock-btn" title={btn2}/>
                                <img className="btn-arr-right" src={rightArr}/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            })
        }
      </Swiper>
    </>
  );
}
