import React from "react";
import FixedMenu from "../HomePage/menuSlider";
import ContactPageData from "./ContactPageData";
import SectionContactUs from "./sectionContactUs/ContactUs";
import "../../css/ContactPage/contactPage.scss"


const ContactPage = () => {



    return(<div className="contactPage">
            <FixedMenu/>
            <ContactPageData/>
            <SectionContactUs/>
        </div>)
}


export default ContactPage