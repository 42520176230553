import React from "react";
import roomBoxBackground from "../../../../img/PageRooms/roomBoxBackground.png"
import person from "../../../../img/PageRooms/icons/Person.svg"
import union from "../../../../img/PageRooms/icons/Union.svg"
import bed from "../../../../img/PageRooms/icons/bed.svg"
import "../../../../css/PageRooms/sectionRooms/roomBox/roomBox.scss"
import { useGlobalProvider } from "../../../Providers/GlobalProvider";
import arrRight from "../../../../img/PageRooms/icons/arr.svg"



const RoomBox = () =>{

    const {fontRegular} = useGlobalProvider()

    return(
        <div className="RoomBox">
           <div className="roomBoxMain">
               <div className="background-overflow">
               <img className="box-background" alt="box-background" src={roomBoxBackground}/>
               </div>
               <div className="box-line"></div>
               <h3 style={fontRegular} className="box-title">Մեկտեղանոց</h3>
               <div className="iconsBlock">
                   <img className="box-person" alt="person" src={person}/>
                   <span style={fontRegular} className="box-guest">2-4 հյուր</span>
                   <img className="box-union" alt="union" src={union}/>
                   <span style={fontRegular} className="mm">52<sup>m2</sup></span>
                   <img className="box-bed" alt="box-bed" src={bed}/>
                   <span className="box-twoBox" style={fontRegular}>2 մահճակալ</span>
               </div>
               <div className="btn-blk">
                    <button style={fontRegular} className="knowMore">Իմանալ ավելին <img alt="arr" src={arrRight}/></button>
               </div>
           </div>
        </div>
    )
}




export default RoomBox