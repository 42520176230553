import React from "react";
import "../../../../css/ContactPage/sectionContactUs/sectionSupport/sectionSupport.scss"
import { useGlobalProvider } from "../../../Providers/GlobalProvider";


const SectionSupport = () => {

    const {fontRegular} = useGlobalProvider()

    return(
        <div>
            <h2 style={fontRegular}  className="applyUs">Դիմեք Մեզ:</h2>
            <input style={fontRegular} type="text" placeholder={"Անուն Ազգանուն*"} className="name"/>
            <input style={fontRegular}  type="number" placeholder={"Հեռ. համար*"} className="tell"/>
            <input style={fontRegular}  placeholder={"Էլ. հասցե*"} type="email" className="email"/>
            <textarea style={fontRegular}  placeholder={"Հաղորդագրություն*"} type="text" className="message"/>
            <span style={fontRegular}  className="importantAreas">* Պարտադիր լրացման դաշտեր</span>
            <button style={fontRegular}  className="sendButton">Ուղարկել</button>
        </div>
    )
}


export default SectionSupport