import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Amenities from "../../RoomPage/sectionGarageRooms/amenities/sectionAmenities";
import "../../../css/GarageRoomsPage/secGarageRooms.scss"
import rightArr from "../../../img/RoomPage/rightArr.png"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import garage from "../../../img/roomsWithGarages/garage.png"



const SecGarageRooms = () =>{

    const {fontRegular,fontSemibold} = useGlobalProvider()


    return(<div className="SecGarageRooms">
            
    <div className="start">
            <div className="StartBlock">
            <span style={fontRegular} className="startBlock--rooms">Համարներ</span>
            <img className="rightArr" alt="Arrow" src={rightArr}/>
            <span style={fontRegular} className="startBlock--garageRooms">Ավտոտնակով Համարներ</span>
            </div>
        <div className="SectionContainer__startS">
            <div className="SectionContainer__startS--line"></div>
            <h2 style={fontSemibold} className="SectionContainer__startS--title">Ավտոտնակով Համարներ</h2>
        </div>
    </div>
    <div className="BlockDescrip">
    <img className="fPattern" src={Pattern}/>
        <p style={fontRegular} className="BlockDescrip--p">Անզուգական տեսարժան վայրերը շրջապատում են մեր կենտրոնում տեղակայված հյուրանոցը, և 224 համարներից յուրաքանչյուրը մի հայացք է տալիս կենտրոնական Երևանի բազմաթիվ կողմերից մեկին: Մեր 4+ աստղանի կացարանը նրբագեղ կերպով համատեղում է հայկական պատմական և ավանդական նախշերն ու ձևավորումները՝ համաձայն ամենախստապահանջ</p>
        <img alt="roomsGarage" className="GarageRooms" src={garage}/>
        <Amenities/>
    </div>
    
</div>)
}

export default SecGarageRooms