import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import sauna from "../../../img/hotelPage/sectionSauna/Sauna.png"
// import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import "../../../css/hotelPage/sectionSauna/sauna.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"




const Sauna = () => {

    const {fontRegular,fontSemibold} = useGlobalProvider()

    return(<div className="sauna">
                <img className="fiPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                <div className="SectionContainer__start--line"></div>
                <h2 style={fontSemibold} className="SectionContainer__start--title">Սաունա</h2>
            </div>
            <div className="sectionSaunaContainer">
            <img className="sectionSaunaImg" alt="Sauna" src={sauna}/>
            <div>
                <h5 style={fontRegular}>Հարմարավետ Սաունա</h5>
                <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                
            </div>
        </div>
</div>)
}




export default Sauna