import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/Pool/PoolSec/poolSec.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import poolImg from "../../../img/PoolPage/poolImg.png"
import galleryPool from "../../../img/PoolPage/galleryPool.png"

const SecPool = () => {

    const {fontSemibold,fontRegular} =useGlobalProvider();


    return(<div className="SecPool">
                <img className="PoolPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Լողավազան</h2>
                </div>
                <div className="sectionPoolMain">
                    <div className="txt-block">
                        <h5 style={fontRegular}>Լողավազան</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    </div>
                        <img alt="imgPool" className="sectionPoolImg" src={poolImg}/>
                    
                </div>
                <div className="galleryBlockPool">
                    <img className="galleryPool" src={galleryPool} alt="galleryPool"/>
                </div>
    </div>)
}



export default SecPool