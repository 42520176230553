import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import "../../../css/Restaurant/sectionRest/RestSection.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import galleryRestaurant from "../../../img/RestaurantPage/galleryRestaurant.png"


const SecRestaurant = () => {

    const {fontSemibold,fontRegular} =useGlobalProvider();


    return(<div className="SecRestaurant">
                <img className="restPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Ռեստորան</h2>
                </div>
                <div className="sectionRestMain">
                    <div className="txt-block">
                        <h5 style={fontRegular}>Ռեստորան</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                    </div>
                        <iframe className="sectionRestVideo" src="https://www.youtube.com/embed/x-EZy6gu_38" title="YouTube video player" allowFullScreen></iframe>
                    
                </div>
                <div className="galleryBlockRestaurant">
                    <img className="galleryRestaurant" src={galleryRestaurant} alt="galleryRestaurant"/>
                </div>
    </div>)
}



export default SecRestaurant