import React from "react";
import "../../../css/hotelPage/sectionHotel/sectionHotel.scss"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"



const SectionHotel = () =>{


    const {fontRegular,fontSemibold} =useGlobalProvider();



    return(
        <div className="sectionHotel">
                <img className="fPattern" src={Pattern}/>
            <div className="sectionHotelContainer"> 
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Հյուրանոց</h2>
                </div>
                <div className="sectionHotelMain">
                    <div>
                        <h5 style={fontRegular}>Հոթել Լա Դեֆենս</h5>
                        <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> 14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։
                           <br/><br/> Անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                        </div>
                        <iframe className="sectionHotelVideo" src="https://www.youtube.com/embed/x-EZy6gu_38" title="YouTube video player" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default SectionHotel