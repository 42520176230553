import React from "react";
import "../../css/HeaderStyle/header.scss"
import phone from "../../img/icons/phone_icon.svg"
import location from "../../img/icons/location_icon.svg"
import insatgram from "../../img/icons/instagram_icon.svg"
import facebook from "../../img/icons/facebook_icon.svg"
import darr from "../../img/icons/darr.png"
import Button from "../Global/Button/Button";
import { NavLink } from "react-router-dom";
import { LOGIN_PAGE } from "../../urls";
import { useGlobalProvider } from "../Providers/GlobalProvider";
import { useModalProvider} from "../Providers/ModalProvider";

const Header = () => {
    const {activeLanguage,languageData,ChangeActiveLanguage,innerModal} = useModalProvider();
    const {setLanguage,fontRegular} =useGlobalProvider();
    

    return (
        <header className="header">
            <div className="header__container">
            <div className="header__left">
                 <div className="header__left__FLine"></div>
                 <img className="header__left__phone" alt="Phone-icon" src={phone} />
                 <div style={fontRegular} className="header__left__number"><a href="tel:+374 095 231 568">+374 095 231 568</a></div>
                 <div className="header__left__SLine"></div>
                 <img className="header__left__location" alt="Location-icon" src={location}/>
                 <div style={fontRegular} className="header__left__address">Տիչինա 32</div>
                 <div className="header__left__TLine"></div>
            </div>

            <div className="header__right">
               <a href="http://www.facebook.com"  target="_blank">
                   <img className="header__right__facebook" alt="facebook-icon" src={facebook}/>
                   </a>
                <a href="http://www.instagram.com"  target="_blank">
                <img className="header__right__instagram" alt="Insatgram-icon" src={insatgram}/>
                </a>
                <div className="header__right__Fline"></div>
            <div className="languageHover">
                <div className="header__right__language__block">
                    <div style={fontRegular} className="header__right__language">{activeLanguage}</div>
                    <img className="header__right__darr" alt="arr-down" src={darr}/>
                    
                </div>
                <div className="modalLanguage">
                        {innerModal()}
                    </div>
                
                </div>
                <Button style={fontRegular}
                   cn="reserve"
                   title="ԱՄՐԱԳՐԵԼ"
                 />
                 
            </div>
            </div>
        </header>
    )
}


export default Header;