import React from "react";
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import gymZone from "../../../img/sectionGymZone/gymZone.png"
import "../../../css/homeSliderStyle/sectionGymZone/sectionGymZone.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { FITNESS } from "../../../urls";

const GymZone = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();


    return(
        <div className="sectionGymZone">
            <img className="siPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Ֆիտնես սրահ</h2>
            </div>
            <div className="sectionGymZoneContainer">
                <div>
                    <h5 style={fontRegular}>Մարզվեք մեզ հետ</h5>
                    <p style={fontRegular}>Վաղ առավոտյան մեր հյուրերը հնարավորություն ունեն <br/> վայելելու յոգայի դասընթացների բաց երկնքի տակ և<br/> հարմարավոտ մարզասրահից:<br/>Պահպանեք առողջ ապրելակերպ մեզ հետ:</p>
                   <NavLink to={FITNESS}> <div className="btn-block">
                            <button style={fontRegular}>Իմանալ ավելին</button>
                            <img className="btn-arr-right"  src={arrRight}/>
                    </div></NavLink>
                </div>
                <img className="sectionGymZoneImg" alt="GymZone" src={gymZone} />
            </div>
        </div>
    )
}

export default GymZone