import React from "react";
import HomeSlider from "./HomeSlider";
import sliderFirst from "../../img/swipers/sliderFirst.png"
import sliderSecond from "../../img/swipers/sliderSecond.png"
import sliderThird from "../../img/swipers/sliderThird.jpg"
import SectionHotel from "./sectionHotel/SectionHotel";
import FixedMenu from "./menuSlider";
import SectionRestaurant from "./sectionRestaurant/SectionRestaurant";
import SectionPool from "./sectionPool/SectionPool";
import SectionOutDoorHall from "./sectionOutDoorHall/SecOutDoorHall";
import SectionOffer from "./sectionOffer/SectionOffer";
import KidsZone from "./sectionKidsZone/KidsZone";
import GymZone from "./sectionGymZone/SectionGymZone";

const HomePage = ()=> {
    
    let SliderData=[
        {id:1,
         name:"ԲԱՐԻ ԳԱԼՈՒՍՏ",
         title:"La Defense",
         info:"ՀՅՈՒՐԱՆՈՑ",
         description:"Ամրագրեք Ձեր հանգիստը մեր նրբաճաշակ ու հարմարավետ համարներում ",
         img:sliderFirst,
        },
        {   id:2,
            name:"slider 2",
            title:"title",
            info:"aaaa",
            description:"cndkvcnvd",
            img:sliderSecond,
        },
        {   id:3,
            name:"slider 3",
            title:"title",
            info:"aaaaaa",
            description:"cnasdkvcnvd",
            img:sliderThird,
           }
    ]


    return (
        <div className="homePage">
            <FixedMenu/>
            <HomeSlider SliderData={SliderData}/>
            <SectionHotel/>
            <SectionRestaurant/>
            <SectionPool/>
            <SectionOutDoorHall/>
            <SectionOffer/>
            <KidsZone/>
            <GymZone/>
        </div>
    )
}


export default HomePage;