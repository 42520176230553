import React from "react";
import { useGlobalProvider } from "../Providers/GlobalProvider";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../css/homeSliderStyle/homeSlider.scss"
// import required modules
import { Pagination } from "swiper";

export default function HomeSlider({SliderData}) {

  const {fontRegular,fontSemibold} =useGlobalProvider();

  return (
    <>
      <Swiper
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        
        {
            SliderData.map(({id,name,title,info,description,img})=>{
                return <SwiperSlide key={id}>
                       <div style={{backgroundImage:`url(${img})`}} className="firstSlide">
                           <div className="container">
                            <div className="container__sliderData">
                              <h1 style={fontSemibold}>{name}</h1>
                              <h2 >{title}</h2>
                              <h3 style={fontSemibold}>{info}</h3>
                              <p style={fontRegular}>{description}</p>
                            </div>
                           </div>
                       </div>
                      </SwiperSlide>
            })
        }
      </Swiper>
    </>
  );
}