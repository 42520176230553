import React, { useEffect } from "react";
import FixedMenu from "../HomePage/menuSlider";
import RoomPageData from "./RoomPageData";
import SecGarRoAllRooms from "./secGarRoAllRooms/SecGarRoAllRooms";
import SectionGarageRooms from "./sectionGarageRooms/sectionGarageRooms";
import SectionSlider from "./sectionGarageRooms/sectionSliderRoom/sectionSlider";



const RoomPage = () => {


    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div className="RoomPage">
            <FixedMenu/>
            <RoomPageData/>
            <SectionGarageRooms/>
            <SectionSlider/>
            <SecGarRoAllRooms/>
        </div>
    )
}


export default RoomPage