import React from "react";
import PicForRes from "../../../img/sectionRestaurant/PicForRes.png"
import "../../../css/homeSliderStyle/sectionRestaurant/sectionRestaurant.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { RESTAURANTPAGE } from "../../../urls";

const SectionRestaurant = () => {


    const {fontThin,fontSemibold,fontRegular} =useGlobalProvider();


    return(
        <div className="sectionRestaurant">
            <img className="sPattern" src={Pattern}/>
                <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Ռեստորան</h2>
                </div>
            <div className="secRestaurant">
                <img  className="picRes" alt="Pic gallery for restaurant" src={PicForRes}/>
                <div className="instFaceBlock">
                <a href="http://www.instagram.com"  target="_blank">
                    <h2 style={fontThin} className="secResInstagram">instagram</h2>
                    </a>
                    <a href="http://www.facebook.com"  target="_blank">
                    <h2 style={fontThin} className="secResFacebook">facebook</h2>
                    </a>
                </div>
            </div>
            <div className="blockForMiddleBlock">
      <NavLink to={RESTAURANTPAGE}><div className="btn-block">
                <button style={fontRegular}>Իմանալ ավելին</button>
                <img className="btn-arr-right"  src={arrRight}/>
            </div></NavLink>
            </div>
        </div>
    )
}


export default SectionRestaurant