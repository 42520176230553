import React from "react";
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import Contact from "./sectionContact/sectionContact";
import "../../../css/ContactPage/sectionContactUs/contactUs.scss"
import GoogleMap from "./googleMap/Map";
import SectionSupport from "./sectionSupport/SectionSupport";

const SectionContactUs = () => {

    const {fontSemibold} = useGlobalProvider()


    return(<div className="heightPage">
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Հետադարձ կապ</h2>
            </div>
            <div className="sectionContactUs">
                <div className="contactMapBlock">
                    <div className="contacts">
                        <Contact/>
                    </div>
                    <div className="map">
                        <GoogleMap/>
                    </div>
                </div>
                    <div className="support">
                        <SectionSupport/>
                    </div>
            </div>
    </div>)
}


export default SectionContactUs