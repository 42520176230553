import React from "react";
import "../../../css/homeSliderStyle/sectionOutDoor/sectionOutdoorHall.scss"
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import outDoorHall from "../../../img/sectionOutDoorHall/outDoorHall.png"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { OPENAIR } from "../../../urls";

const SectionOutDoorHall = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();

    
    return(
        <div className="secionOutDoorHall">
            <img className="foPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Բացօթյա սրահ</h2>
            </div>
            <div className="sectionOutDoorContainer">
                <div>
                    <h5 style={fontRegular}>Գեղեցիկ բացօթյա սրահներ</h5>
                    <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                   <NavLink to={OPENAIR}><div className="btn-block">
                            <button style={fontRegular}>Իմանալ ավելին</button>
                            <img className="btn-arr-right"  src={arrRight}/>
                    </div></NavLink>
                </div>
                <img className="sectionOutDoorHallImg" alt="OutDoorHall" src={outDoorHall}/>
            </div>
        </div>
    )
}


export default SectionOutDoorHall