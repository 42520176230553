import React from "react";
import pool from "../../../img/sectionPool/sectionPool.jpg"
import arrRight from "../../../img/sectionHotel/patterns/arrRight.png"
import "../../../css/homeSliderStyle/sectionPool/sectionPool.scss"
import Pattern from "../../../img/sectionHotel/patterns/Vector.png"
import { useGlobalProvider } from "../../Providers/GlobalProvider";
import { NavLink } from "react-router-dom";
import { POOLPAGE } from "../../../urls";

const SectionPool = () => {

    const {fontRegular,fontSemibold} =useGlobalProvider();


    return(
        <div className="sectionPool">
            <img className="thPattern" src={Pattern}/>
            <div className="SectionContainer__start">
                    <div className="SectionContainer__start--line"></div>
                    <h2 style={fontSemibold} className="SectionContainer__start--title">Լողավազան</h2>
            </div>
            <div className="sectionPoolContainer">
                <img className="sectionPoolImg" alt="Pool" src={pool}/>
                <div>
                    <h5 style={fontRegular}>Բացօթյա լողավազան և ՍՊԱ</h5>
                    <p style={fontRegular}>14-րդ հարկում գտնվող Thai Penthouse սպա վերականգնողական կենտրոնը լավագույն վայրն է անմոռանալի ժամանակ անցկացնելու համար՝ իրական հանգիստ բարձրակարգ մասնագետների շնորհիվ։</p>
                <NavLink to={POOLPAGE}><div className="btn-block">
                            <button style={fontRegular}>Իմանալ ավելին</button>
                            <img className="btn-arr-right"  src={arrRight}/>
                    </div></NavLink>
                </div>
            </div>
        </div>
    )
}



export default SectionPool