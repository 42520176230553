import React from "react";
import FsliderRoom from "../../../img/RoomPage/FsliderRoom.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination,FreeMode,Navigation } from "swiper";

export default function SliderGarageRoom() {



  return (
    <>
      <Swiper
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Navigation,Pagination]}
        className="mySwiper"
        navigation
        
      >
        
        {<div className="roomGarageSlider">
             <SwiperSlide>
                       <div style={{backgroundImage:`url(${FsliderRoom})`}} className="frSlideBackground">
                         
                       </div>
              </SwiperSlide>
                <SwiperSlide>
                <div style={{backgroundImage:`url(${FsliderRoom})`}} className="frSlideBackground">
                  
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div style={{backgroundImage:`url(${FsliderRoom})`}} className="frSlideBackground">
                  
                </div>
                </SwiperSlide>
                </div>
        }
      </Swiper>
    </>
  );
}