import React from "react";
import roomBackground from "../../img/RoomPage/roomBackground.png"
import { useGlobalProvider } from "../Providers/GlobalProvider";

const RoomPageData = () =>{

    const {fontSemibold} = useGlobalProvider()

    return(<div>
        <div className="mainBackground" style={{backgroundImage:`url(${roomBackground}`}}>
        <div className="contactContainer">
                <div className="contactContainerData">
                <h1>La Defense</h1>
                <h2 style={fontSemibold}>Մեկտեղանոց Համարներ</h2>
                </div>
            </div>
        </div>
        </div>
        
    )
}



export default RoomPageData